<template>
  <div class="detail publicBox" v-if="details">
    <div class="head">
      <div class="left">
        <div class="name">
          {{details.userName}}
        </div>
        <div class="baseData">
          <div>
            负责人：{{details.userCharge}}
          </div>
          <div>
            联系人：{{details.nickName}}
          </div>
          <div>
            注册时间：{{details.createAt}}
          </div>
          <div>
            所属市场编号：{{details.hsbMktMerchantInfo && details.hsbMktMerchantInfo.mktId}}
          </div>
        </div>
        <div class="btns">
          <myButton title="编辑客户" right @myclick="gopage(20)"></myButton>
          <myButton title="禁用客户" right v-if="details.userStatus == 10" @myclick="forbiuse(20)"></myButton>
          <myButton title="启用客户" right v-if="details.userStatus == 20" @myclick="forbiuse(10)"></myButton>
          <myButton title="设置" @myclick="$router.push({path: `/client/setting?id=${id}`})" v-if="details.userRecycle == 10"></myButton>
        </div>
      </div>
      <div class="right">
        <img :src="details.userLogo" alt="">
      </div>
    </div>
    <div class="publicBoxTitle">
      基本信息
    </div>
    <div class="publicBoxContent basicInformation">
      <div class="left">
        <div class="list">
          <div class="name">
            客户名称：
          </div>
          <div class="content">
            {{details.userName}}
          </div>
        </div>
        <div class="list">
          <div class="name">
            客户类型：
          </div>
          <div class="content">
            {{details.userType == 10 ? '个人' : '企业'}}
          </div>
        </div>
        <div class="list">
          <div class="name">
            联系人：
          </div>
          <div class="content">
            {{details.nickName}}
          </div>
        </div>
        <div class="list">
          <div class="name">
            证件号码：
          </div>
          <div class="content">
            {{details.userIdCode}}
          </div>
        </div>
        <div class="list">
          <div class="name">
            购买类型：
          </div>
          <div class="content">
            {{details.buyerType == 10 ? '云系统' : '私有化系统部署'}}
          </div>
        </div>
      </div>
      <div class="right">
        <div class="list">
          <div class="name">
            客户ID：
          </div>
          <div class="content">
            {{id}}
          </div>
        </div>
        <div class="list">
          <div class="name">
            地址：
          </div>
          <div class="content">
            {{details.userAddress}}
          </div>
        </div>
        <div class="list">
          <div class="name">
            联系方式：
          </div>
          <div class="content">
            {{details.userPhone}}
          </div>
        </div>
        <div class="list">
          <div class="name">
            证件图片：
          </div>
          <div class="content">
            <myButton title="查看" @myclick="dialogVisible = true"></myButton>
          </div>
        </div>
        <div class="list">
          <div class="name">
            收款银行类型：
          </div>
          <div class="content">
            {{details.bankType == 10 ? '特約' : '自签'}}
          </div>
        </div>
      </div>
    </div>
    <div class="publicBoxTitle">
      购买产品
    </div>
    <div class="publicBoxContent">
      <el-table :data="details.cmsOrder" :cell-style="{'text-align':'center'}" :header-cell-style="{'text-align':'center','background':'#F5F5F5'}">
  			<el-table-column prop="productName" label="产品名称"></el-table-column>
  			<el-table-column prop="orderNo" label="订单编号"></el-table-column>
  			<el-table-column prop="systemName" label="所属系统"></el-table-column>
  			<el-table-column prop="orderPrice" label="产品价格"></el-table-column>
        <el-table-column prop="createAt" label="购买时间"></el-table-column>
  		</el-table>
    </div>
    <div class="publicBoxTitle">
      市场信息
    </div>
    <div class="publicBoxContent basicInformation">
      <div class="left">
        <div class="list">
          <div class="name">
            所属市场名称：
          </div>
          <div class="content">
            {{details.hsbMktMerchantInfo && details.hsbMktMerchantInfo.mktName}}
          </div>
        </div>
        <div class="list">
          <div class="name">
            主体银行账户名称：
          </div>
          <div class="content">
            {{details.hsbMktMerchantInfo && details.hsbMktMerchantInfo.mktMrchNm}}
          </div>
        </div>
        <div class="list">
          <div class="name">
            收取客户手续费用：
          </div>
          <div class="content">
            {{details.hsbEnterpriseMerchant && details.hsbEnterpriseMerchant.charge == 1 ? details.hsbEnterpriseMerchant.chargeType == 1 ? '百分比' : '单笔固定' : '不收取'}}
          </div>
        </div>
        <div class="list">
          <div class="name">
            客户收取商家单笔固定手续费限制：
          </div>
          <div class="content">
            {{details.hsbEnterpriseMerchant && details.hsbEnterpriseMerchant.chargeFixedAmount}}元
          </div>
        </div>
      </div>
      <div class="right">
        <div class="list">
          <div class="name">
            所属市场编号：
          </div>
          <div class="content">
            {{details.hsbMktMerchantInfo && details.hsbMktMerchantInfo.mktId}}
          </div>
        </div>
        <div class="list">
          <div class="name">
            账户商家编号：
          </div>
          <div class="content">
            {{details.hsbMktMerchantInfo && details.hsbMktMerchantInfo.mktMrchId}}
          </div>
        </div>
        <div class="list">
          <div class="name">
            {{details.hsbEnterpriseMerchant.chargeType == 1 ? '百分比率：' : '单笔固定费用：'}}
          </div>
          <div class="content">
            <span v-if="details.hsbEnterpriseMerchant.charge == 1">
              {{details.hsbEnterpriseMerchant.chargeValue}}
            </span>
          </div>
        </div>
        <div class="list">
          <div class="name">
            客户收取商家百分比手续费限制：
          </div>
          <div class="content">
            {{details.hsbEnterpriseMerchant && details.hsbEnterpriseMerchant.chargeRate}}%
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="证件" :visible.sync="dialogVisible" width="880px">
      <div class="imgBox">
        <div class="left">
          <img :src="details.idCardJust" alt="">
        </div>
        <div class="right">
          <img :src="details.idCardBack" alt="">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <myButton @myclick="dialogVisible = false">关闭</myButton>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    name: 'detail',
    data() {
      return {
        id: '',
        details: null,
        dialogVisible: false
      };
    },
    created(){
      this.id = this.$route.query.id;
      this.getDetail();
    },
    methods: {
      forbiuse(type) {
				this.$request.HttpPost('/admin/cmsUser/status', {
					userId: this.id,
					userStatus: type
				}).then(res => {
					this.getDetail()
				})
			},
      gopage(type) {
				//0新增10审核20编辑
				this.$router.push({
					path: `/client/addClient?id=${this.id}&type=${type}`
				});
			},
      getDetail() {
        this.$request.HttpGet('/admin/cmsUser/details', {
          id: this.id
        }).then(res => {
          res.data.hsbEnterpriseMerchant = res.data.hsbEnterpriseMerchant ? res.data.hsbEnterpriseMerchant : {};
          res.data.hsbMktMerchantInfo = res.data.hsbMktMerchantInfo ? res.data.hsbMktMerchantInfo : {};
          this.details = res.data;
        });
      }
    }
  };
</script>
<style lang="scss" scoped>
  .detail {
    height: calc(100vh - 146px);
    overflow-y: auto;
    .head {
      margin-top: 20px;
      border: 1px solid #ededed;
      display: flex;
      justify-content: space-between;
      padding: 20px 30px;
      .left {
        .name {
          font-size: 22px;
          color: #333333;
          font-weight: bold;
        }
        .baseData {
          display: flex;
          margin-top: 15px;
          color: #999999;
          div {
            margin-right: 25px;
          }
        }
        .btns {
          margin-top: 20px;
        }
      }
      .right {
        img {
          width: 110px;
          height: 110px;
        }
      }
    }
    .basicInformation {
      display: flex;
      .left, .right {
        flex: 1;
        border-left: 1px solid #dedede;
        border-top: 1px solid #dedede;
        .list {
          display: flex;
          height: 40px;
          border-bottom: 1px solid #dedede;
          .name {
            width: 160px;
            text-align: center;
            background: rgb(245, 245, 245);
            align-items: center;
            display: flex;
            justify-content: center;
            border-right: 1px solid #dedede;
          }
          .content {
            padding-left: 20px;
            align-items: center;
            display: flex;
            justify-content: center;
          }
        }
      }
      .right {
        border-right: 1px solid #dedede;
      }
    }
    .imgBox {
      display: flex;
      .left {
        margin-right: 15px;
      }
      .left, .right {
        flex: 1;
        img {
          width: 100%;
          height: 200px;
        }
      }
    }
  }
</style>
